import './App.css';
import ReactGA from 'react-ga';
import WordleHint from './components/WordleHint'

const TRACKING_ID = "G-RM3CLF6RCC";
ReactGA.initialize(TRACKING_ID);

const todaysDate = new Date();
const todaysDateStr = todaysDate.toString().split(' ').splice(1, 3).join(' ');

function App() {
  return (
    <div className='App'>
      <h2>Click a box</h2>
      <h3>to reveal a letter</h3>
      <h4>of today's wordle answer...</h4>
      <h5>{todaysDateStr}</h5>
      <WordleHint />
    </div>
  );
}

export default App;
