import React, { Component } from 'react'

class DisappearingBox extends Component {
    constructor() {
        super();
        this.state = {show: true};
        this.woop = this.woop.bind(this);
    }
    woop() {
        this.setState({show: false});
    }
    render() {
        return (
            <div onClick={this.woop} style={{visibility: this.state.show ? 'visible' : 'hidden'}} className='disappearing-box'></div>
        )
    }
}

class ClickableLetter extends Component {
  render() {
    return (
      <div className='letter-box'>
        <div className='clickable-letter'>
            {this.props.letter}
        </div>
        <DisappearingBox />
      </div>
    )
  }
}

export default ClickableLetter;